module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"B_I MEDIEN Kürzungs-Konfigurator","short_name":"BIKK","start_url":"/","background_color":"#E5EDF0","display":"standalone","icon":"src/assets/b-i-favicon.jpg","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"15353691860a65a77b8fcd91b2c44436"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
